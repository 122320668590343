body {
  margin: 0;
  font-family: -apple-system, BlinkMacSystemFont, "Segoe UI", "Roboto", "Oxygen",
    "Ubuntu", "Cantarell", "Fira Sans", "Droid Sans", "Helvetica Neue",
    sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}

code {
  font-family: source-code-pro, Menlo, Monaco, Consolas, "Courier New",
    monospace;
}

.credits {
  text-align: center;
}

/* ANGULAR MOVIE CLIENT CASE STUDY */

.angular-movie-hero {
  background-image: url("/public/felix-mooneeram-angular-movie-theater.png");
  background-position: center;
}

.angular-documentation {
  width: 100%;
}

/* MEET CASE STUDY */
.meet-hero {
  background-image: url("/public/nasa-Q1p7bh3SHj8-unsplash.jpg");
  background-position: right;
  border-radius: 3px;
}

.meet-cs-screenshot {
  width: 80%;
}

.meet-hero-credit {
  font-size: smaller;
}

.mini-dividers {
  margin-left: 80% !important;
}

.bi-arrow-right {
  margin-inline: 10px;
}

.carousel-img {
  max-width: 600px;
  max-height: 500px;
}

.carousel-item {
  text-align: center;
}
