body {
  margin: 0;
  font-family: -apple-system, BlinkMacSystemFont, "Segoe UI", "Roboto", "Oxygen",
    "Ubuntu", "Cantarell", "Fira Sans", "Droid Sans", "Helvetica Neue",
    sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}

code {
  font-family: source-code-pro, Menlo, Monaco, Consolas, "Courier New",
    monospace;
}

/* light mode colors: */
#root,
html {
  /* background-color: #000000; */
  /* color: #393939; */
}

/* HOME PAGE */

.heading__line-1 span {
  color: #ff4d5a;
  font-weight: 400;
}

.heading__line-1,
.heading__line-2 {
  font-size: 3rem;
  line-height: 1.1;
}

@media (max-width: 700px) {
  .heading__line-1,
  .heading__line-2 {
    font-size: 5rem;
    text-align: center;
    margin-left: 4rem;
    margin-right: 4rem;
  }
}

/* Shrinks - mobile text to size*/
@media (max-width: 550px) {
  .heading__line-1,
  .heading__line-2 {
    font-size: 2rem;
  }
}

.intro {
  height: 79vh;
}

.intro-col {
  display: flex;
  justify-content: center;
  align-items: center;
  height: 80vh;
  text-align: center;
  flex-direction: column;
}

.intro-title {
  margin: 0;
  line-height: 0.85;
  color: #faf7f5;
  font-size: 60px;
  font-weight: 300;
  transform: translateY(-80%);
}

.blob1-wrapper {
  position: relative;
}

.blob1 {
  margin: 0;
  position: absolute;
  z-index: -1;
  filter: blur(40px);
  opacity: 0.6;
}

.blob2-wrapper {
  position: relative;
}

.blob2 {
  margin: 0;
  position: absolute;
  z-index: -3;
  filter: blur(40px);
  opacity: 0.8;
}

/* PORTFOLIO */
.portfolio-items-container {
  flex-direction: column;
}

.portfolio-img {
  border-radius: 20px;
  width: 100%;
}

.project-links {
  justify-content: space-evenly;
}

.small-image {
  width: 50%;
  height: auto;
}

.center-image {
  display: flex;
  justify-content: center;
}

/* RESUME */
.resume-contact {
  text-align: right;
}

/* ABOUT */
.portrait {
  width: 250px;
  border-radius: 3px;
}

.codepen {
  fill: #fff;
  height: 24px;
  width: 32.5px;
}

/* FOOTER */
.footer {
  text-align: center;
  width: 100%;
  z-index: 1;
  opacity: 0.65;
}

/* MEDIA QUERIES */
@media (min-width: 990px) {
  .intro-title {
    margin-top: 30%;
  }
}

@media (max-width: 650px) {
  .intro-title {
    font-size: 40px;
  }
}

@media (max-width: 768px) {
  .blob1,
  .blob2 {
    width: 100%;
    /* Adjust as necessary to fill the screen */
    transform: translateY(20%);
    /* transform: translateX(-5%); */
  }
}
